import React from 'react';
import PropTypes, { bool } from 'prop-types';
import { useIntl } from 'react-intl';
import { SummaryBlock } from '../../Common/Summary';

const PortfolioSummary = ({
  vintageYear,
  targetAllocation,
  numberOfInvestments,
  totalInvested,
  currency,
  loading,
}) => {
  const intl = useIntl();
  const investedPercent =
    targetAllocation !== 0
      ? Math.floor(totalInvested / targetAllocation) * 100
      : -1;
  const investedPercentCaption =
    investedPercent > 0 ? `(${investedPercent}%)` : '';
  const formattedSummary = {
    vintageYear,
    targetAllocation: intl.formatNumber(targetAllocation, {
      style: 'currency',
      currency,
    }),
    numberOfInvestments,
    totalInvested: `${intl.formatNumber(totalInvested, {
      style: 'currency',
      currency,
    })} ${investedPercentCaption}`,
  };
  return <SummaryBlock completeSummary={formattedSummary} loading={loading} />;
};

PortfolioSummary.propTypes = {
  vintageYear: PropTypes.number.isRequired,
  targetAllocation: PropTypes.number.isRequired,
  numberOfInvestments: PropTypes.number.isRequired,
  totalInvested: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  loading: bool,
};

PortfolioSummary.defaultProps = {
  loading: false,
};

export default PortfolioSummary;
