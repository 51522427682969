import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Typography, Grid, Button } from '@material-ui/core';
import messages from './messages';
import { useStyle } from './styles';

const DownloadModal = ({ sheetUrl, closeModal }) => {
  const intl = useIntl();
  const classes = useStyle();
  return (
    <Grid container style={{ width: 590 }}>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>
          {intl.formatMessage(messages.downloadModel)}
        </Typography>
      </Grid>
      {sheetUrl.map((ele) => (
        <Grid style={{ display: 'flex' }} item xs={12}>
          <Typography
            className={classes.downloadModalTypography}
            variant="body1"
            gutterBottom
          >
            {ele}
          </Typography>
          {sheetUrl.length > 1 && (
            <Button
              className={classes.roundedButton}
              style={{ marginLeft: '10px' }}
              color="primary"
              variant="contained"
              onClick={() => navigator.clipboard.writeText(ele)}
            >
              {intl.formatMessage(messages.copyToClipboard)}
            </Button>
          )}
        </Grid>
      ))}
      <Grid style={{ marginTop: 20 }} item xs={12} direction="row">
        <Button
          className={classes.roundedButton}
          variant="outlined"
          onClick={closeModal}
        >
          {intl.formatMessage(messages.close)}
        </Button>
        {sheetUrl.length === 1 && (
          <Button
            className={classes.roundedButton}
            style={{ marginLeft: '10px' }}
            color="primary"
            variant="contained"
            onClick={() => navigator.clipboard.writeText(sheetUrl)}
          >
            {intl.formatMessage(messages.copyToClipboard)}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

DownloadModal.propTypes = {
  sheetUrl: PropTypes.string,
  closeModal: PropTypes.func,
};

DownloadModal.defaultProps = {
  sheetUrl: '',
  closeModal: () => {},
};

export default DownloadModal;
