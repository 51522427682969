/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { connect, useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';

import TabPanel from 'components/User/TabPanel';
import { userRoles } from 'user/userConstants';
import { CommonAction, OrganizationAction } from 'actions';
import { getSelectedOrganization } from 'reducers';
import { UserThunk } from 'containers/User';
import {
  selectSelectedOrganizationById,
  selectOrganizations,
  selectorOrganizationRoot,
  selectSelectedOrganization,
  OrganizationThunk,
} from 'containers/Organisation';
import translations from 'translations';
import { setAlert, setLoading } from 'containers/Config';
import messages from './messages';

const ManageUsersContainer = ({ getAllOrganizations, deleteOrganistaion }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { state: { companyOrganisation: companyOrg } = {} } = location;
  const allOrganizations = useSelector(selectOrganizations);
  const organization = useSelector(selectSelectedOrganization);
  const selectedOrgIndex = useSelector((state) =>
    selectSelectedOrganizationById(selectorOrganizationRoot(state), companyOrg)
  );
  const companyOrganisation = allOrganizations[selectedOrgIndex];
  // https://trello.com/c/AbhdxLLQ/244-keep-org-selected-when-going-to-manage-users
  // const companyOrganisation = allOrganizations.find(
  //   (org) => org.id === location.state.companyOrganisation
  // );

  const onSuccessUpdateContact = () => {
    dispatch(
      setAlert({
        open: true,
        message: messages.onSuccessUpdateContact,
        severity: 'success',
      })
    );
  };

  const onSuccessUpdateOrganisation = () => {
    dispatch(
      setAlert({
        open: true,
        message: messages.onSuccessUpdateOrganisation,
        severity: 'success',
      })
    );
  };

  const onSuccessUpdateUserRole = () => {
    dispatch(
      setAlert({
        open: true,
        message: messages.onSuccessUpdateUserRole,
        severity: 'success',
      })
    );
  };

  // Sharing organisation handlers
  const onSuccessShareOrganisation = () => {
    dispatch(
      setAlert({
        open: true,
        message: messages.onSuccessShareOrganisation,
        severity: 'success',
      })
    );
  };

  const onErrorShareOrganisation = () => {
    dispatch(
      setAlert({
        open: true,
        message: 'Something went wrong',
        severity: 'error',
      })
    );
  };

  // Unsharing organisation handlers
  const onSuccessUnshareOrganisation = () => {
    dispatch(
      setAlert({
        open: true,
        message: messages.onSuccessUnshareOrganisation,
        severity: 'success',
      })
    );
  };

  const onErrorUnshareOrganisation = () => {
    dispatch(
      setAlert({
        open: true,
        message: 'Something went wrong',
        severity: 'error',
      })
    );
  };

  const handleShowLoader = (state) => {
    dispatch(setLoading(state));
  };

  const onCreateUser = async ({ email, role, firstName, lastName }) => {
    const { id } = await new Promise((resolve, reject) =>
      dispatch(
        UserThunk.createUser(
          {
            organisationId: companyOrganisation.id,
            email,
            role,
            firstName,
            lastName,
          },
          {
            reject,
            resolve,
            successMsg: (
              <FormattedMessage
                {...translations.User.ManageUsers.onSuccessCreateUser}
              />
            ),
          }
        )
      )
    );
    return id;
  };

  const onUpdateUser = async ({ firstName, lastName, id }) => {
    dispatch(
      UserThunk.updateUser(
        {
          organisationId: companyOrganisation.id,
          id,
          firstName,
          lastName,
        },
        {
          successMsg: (
            <FormattedMessage
              {...translations.User.ManageUsers.onSuccessUpdateUser}
            />
          ),
        }
      )
    );
  };

  const onDeleteUser = async (userId) => {
    await new Promise((resolve, reject) =>
      dispatch(
        UserThunk.deleteUser(
          {
            userIds: [userId],
            organisationId: companyOrganisation.id,
          },
          {
            successMsg: (
              <FormattedMessage
                {...translations.User.ManageUsers.onSuccessRemoveUser}
              />
            ),
            reject,
            resolve,
          }
        )
      )
    );
  };

  const onUpdateUserRole = async ({ role, id }) => {
    await new Promise((resolve, reject) =>
      dispatch(
        UserThunk.updateUserRole(
          {
            id,
            role: Object.values(userRoles)[role],
            organisationId: companyOrganisation.id,
          },
          {
            successMsg: (
              <FormattedMessage
                {...translations.User.ManageUsers.onSuccessUpdateUser}
              />
            ),
            reject,
            resolve,
          }
        )
      )
    );
  };

  useEffect(() => {
    if (!companyOrganisation || !companyOrganisation.id) return;
    if (
      organization.id !== companyOrganisation.id &&
      !Array.isArray(companyOrganisation.users) // to break infinite loop
    ) {
      dispatch(
        OrganizationThunk.getSelectedOrganization(companyOrganisation.id, false)
      );
    }
  }, [companyOrganisation]);

  const onUpdateContact = async ({
    email,
    firstName,
    id,
    lastName,
    userId,
  }) => {
    dispatch(
      UserThunk.updateContact(
        {
          email,
          firstName,
          id,
          lastName,
          userId,
          orgId: companyOrganisation.id,
        },
        {
          successMsg: (
            <FormattedMessage
              {...translations.Company.ManageCompanies.Update
                .onSuccessUpdateContact}
            />
          ),
        }
      )
    );
  };

  const onDeleteContact = async ({ id }) => {
    if (!id) return;
    dispatch(
      UserThunk.deleteContact(
        {
          ids: [id],
          organisationId: companyOrganisation.id,
        },
        {
          successMsg: (
            <FormattedMessage
              {...translations.Company.ManageCompanies.Update
                .onSuccessUpdateContact}
            />
          ),
        }
      )
    );
  };

  return (
    <>
      {companyOrganisation && companyOrganisation.id ? (
        companyOrganisation.role !== userRoles.ADMIN ? (
          <Redirect to="/" />
        ) : (
          <TabPanel
            selectedOrganisation={companyOrganisation}
            onErrorShareOrganisation={onErrorShareOrganisation}
            onSuccessShareOrganisation={onSuccessShareOrganisation}
            onErrorUnshareOrganisation={onErrorUnshareOrganisation}
            onSuccessUnshareOrganisation={onSuccessUnshareOrganisation}
            onSuccessUpdateOrganisation={onSuccessUpdateOrganisation}
            onSuccessUpdateUserRole={onSuccessUpdateUserRole}
            onSuccessUpdateContact={onSuccessUpdateContact}
            getAllOrganizations={getAllOrganizations}
            deleteOrganistaion={deleteOrganistaion}
            onCreateUser={onCreateUser}
            onUpdateUser={onUpdateUser}
            onDeleteUser={onDeleteUser}
            onUpdateUserRole={onUpdateUserRole}
            onUpdateContact={onUpdateContact}
            onDeleteContact={onDeleteContact}
            setLoading={handleShowLoader}
          />
        )
      ) : (
        <Redirect to="/" />
      )}
    </>
  );
};

const mapState = createStructuredSelector({
  organisation: getSelectedOrganization,
});

const mapDispatch = (dispatch) => ({
  setAlert: (alert) => dispatch(CommonAction.setAlert(alert)),
  getAllOrganizations: () => dispatch(OrganizationAction.getOrganizations()),
  deleteOrganistaion: (id) =>
    dispatch(OrganizationAction.deleteOrganistaion(id)),
});

export default connect(mapState, mapDispatch)(ManageUsersContainer);
