import { makeStyles } from '@material-ui/core/styles';
import { Colors } from 'constants/colors';

const focusBlack = {
  borderColor: Colors.TEXT,
  borderStyle: 'solid',
  borderWidth: 1,
};

const useStyles = makeStyles((theme) => ({
  grid: {
    padding: theme.spacing(3),
  },
  paper: {
    padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
  },
  gridWrapper: {
    margin: theme.spacing(2),
  },
  actionBtn: {
    marginLeft: theme.spacing(5),
    width: '100px !important',
    alignSelf: 'flex-end',
  },
  gridCol1: {
    marginRight: theme.spacing(3),
    width: '40%',
  },
  gridCol2: {
    width: '60%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  cardBox2: {
    width: '30%',
  },
  cardBox3: {
    width: '60%',
  },
  gridTitle: {
    paddingLeft: '26px',
  },
  formControlCol: {
    '& label.Mui-focused': {
      color: Colors.TEXT,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': focusBlack,
      '&.Mui-focused fieldset': focusBlack,
    },
  },
}));

// eslint-disable-next-line import/prefer-default-export
export { useStyles };
