import React from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';

import translations from 'translations';
import { DeleteButton } from 'components/Common/Buttons';
import { GridTitle } from 'components/Common/Grid';
import { useStyles } from './styles';

const PorfolioSettings = (): JSX.Element => {
  const { formatMessage } = useIntl();
  const classes = useStyles();

  return (
    <>
      {/* DELETE PORFOLTIO GRID */}
      <GridTitle
        textId={translations.Portfolio.DeletePortfolio.id}
        text={translations.Portfolio.DeletePortfolio.defaultMessage}
        classStyles={classes.gridTitle}
      />
      <Grid item xs={12} className={classes.gridWrapper}>
        <Paper className={classes.paper} elevation={0} variant="outlined">
          <Grid container direction="row" alignItems="center">
            <Grid item xs={12} sm={8} lg={6}>
              <Typography
                // component="subtitle2"
                variant="subtitle2"
                color="textPrimary"
                gutterBottom
              >
                {formatMessage(
                  translations.OrganisationSettings.permanentDeletePortfolio
                )}
              </Typography>
            </Grid>
            <Grid item xs={8} sm={4} lg={6}>
              <DeleteButton
                variant="contained"
                color="primary"
                className={classes.actionBtn}
                // onClick={() => {}}
              >
                {formatMessage(translations.Common.common.delete)}
              </DeleteButton>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </>
  );
};

export { PorfolioSettings };
