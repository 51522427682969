/* eslint-disable global-require */
import {
  configureStore,
  Middleware,
  Action,
  combineReducers,
} from '@reduxjs/toolkit';
import thunk, { ThunkAction } from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import { createLogger } from 'redux-logger';
import reducer from './reducer';

const history = createBrowserHistory();
const isLogged = process.env.NODE_ENV !== 'production';

const middleware: Array<Middleware> = [thunk];
if (history) {
  middleware.push(routerMiddleware(history));
}
if (isLogged) {
  const logger = createLogger();
  // middleware.push(logger);
}

const rootReducer = combineReducers({
  ...reducer,
  router: connectRouter(history),
});

const store = configureStore({
  reducer: rootReducer,
  middleware,
  devTools: {
    name: 'keytrack-redux',
  },
});

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./reducer', () => {
    const newRootReducer = require('./reducer').default;
    store.replaceReducer(newRootReducer);
  });
}

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, null, Action<any>>;
export { store };
