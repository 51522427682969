import React from 'react';
import { PorfolioSettings } from 'components/Portfolio/PorfolioSettings';

const PortfolioSettingsContainer = () => {
  return (
    <>
      <PorfolioSettings />
    </>
  );
};

export default PortfolioSettingsContainer;
